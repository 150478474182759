import React from "react";
//import data
import { footer } from "../data";

const Footer = () => {
  const { logo } = footer;
  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between mr-40">
          <div
            className="mb-6 md:mb-0"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-delay="300"
          >
            <a href="/" className="flex items-center">
              <img className="w-[200px]" src={logo} alt="" />
            </a>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 sm:gap-6 sm:grid-cols-3">
            <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="500">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                About
              </h2>
              <ul className="text-gray-600 dark:text-gray-400 text-sm">
                <li className="mb-4">
                  <a
                    href="#mission"
                    className="transition-all hover:text-gradient"
                  >
                    Our Story
                  </a>
                </li>
                <li>
                  <a
                    href="#features"
                    className="transition-all hover:text-gradient"
                  >
                    Features
                  </a>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="700">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Resources
              </h2>
              <ul className="text-gray-600 dark:text-gray-400 text-sm">
                <li className="mb-4">
                  <a
                    href="#plans"
                    className="transition-all hover:text-gradient"
                  >
                    Plans
                  </a>
                </li>
                <li>
                  <a
                    href="#process"
                    className="transition-all hover:text-gradient"
                  >
                    How it Works
                  </a>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="900">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Follow us
              </h2>
              <ul className="text-gray-600 dark:text-gray-400 text-sm">
                <li className="mb-4">
                  <a
                    href="https://twitter.com/demystifymoney"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transition-all hover:text-gradient"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/my-financial-therapist/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transition-all hover:text-gradient"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="1000">
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                How it Works
              </h2>
              <ul className="text-gray-600 dark:text-gray-400 text-sm">
                <li className="mb-4">
                  <a
                    href="#process"
                    className="transition-all hover:text-gradient"
                  >
                    Process
                  </a>
                </li>
                <li>
                  <a href="#cta" className="transition-all hover:text-gradient">
                    Sign up
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between ml-10">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {new Date().getFullYear()}{" "}
            <a href="/" className="transition-all hover:text-gradient">
              My Financial Therapist
            </a>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0 mr-10">
            <a
              href="https://www.linkedin.com/company/my-financial-therapist/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 50 50"
                width="50px"
                height="50px"
                aria-hidden="true"
              >
                <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" />
              </svg>
              <span className="sr-only">LinkedIn page</span>
            </a>
            <a
              href="https://twitter.com/demystifymoney"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
              <span className="sr-only">Twitter page</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
