import React from "react";
//import data
import { cta } from "../data";
//import icons
// import { HiOutlineChevronDown } from "react-icons/hi";

const Cta = () => {
  //destructure cta data
  const { title } = cta;
  // subtitle, btnText, img1, img2
  return (
    <section
      id="cta"
      className="section btn-gradient bg-cover bg-left-top mt-10"
    >
      <div className="max-w-[1430px] mx-auto px-[25px]">
        {/* text */}
        <div className="max-w-[820px] p-10 rounded-3xl h-[800px] mx-auto bg-white text-center">
          <h2
            className="h2 text-gradient mb-6"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {title}
          </h2>
          <form
            action="https://formsubmit.co/hello@myfinancialtherapist.com"
            method="POST"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <input
                name="first-name"
                placeholder="First Name"
                type="text"
                className="border border-gray-400 rounded py-1 px-2"
              />
              <input
                name="last-name"
                placeholder="Last Name"
                type="text"
                className="border border-gray-400 rounded py-1 px-2"
              />
            </div>
            <div className="mt-8">
              <input
                name="email"
                placeholder="E-Mail"
                type="text"
                className="border border-gray-400 rounded py-1 px-2 w-full"
              />
            </div>
            <div className="mt-8">
              <input
                name="phone"
                placeholder="Phone Number"
                type="tel"
                className="border border-gray-400 rounded py-1 px-2 w-full"
              />
            </div>

            <div className="mt-8 flex flex-col">
              <select
                className="border bg-white border-gray-400 rounded py-1 py-2 cursor-pointer"
                name="select-where"
                id="select-where"
                required
              >
                <option className="pl-4 text-gray-400" value="">
                  Choose your Industry &darr;
                </option>
                <option value="Non-Profit">Non-Profit</option>
                <option value="Higher Education">Higher Education</option>
                <option value="Enterprise">Enterprise</option>
                <option value="Individual / Family">Individual/Family</option>
                <option value="Student">Student</option>
                <option value="Student">Other</option>
              </select>
            </div>
            <div className="mt-8">
              <textarea
                name="message"
                placeholder="Write a message"
                className="border border-gray-400 rounded px-1 py-2 w-full"
                cols="26"
                rows="5"
              ></textarea>
            </div>
            <button
              className="py-4 mt-10 transition duration-500 transform hover:-translate-y-1
            inline-block bg-blue-500 rounded-lg text-white py-1.5 px-4 cursor-pointer w-full"
            >
              Submit
            </button>
            <input
              type="hidden"
              name="_next"
              value="https://www.myfinancialtherapist.com/"
            ></input>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Cta;
