import React from "react";
//Import data

import { features } from "../data";

const Feature4 = () => {
  // destructure features
  const { feature4 } = features;
  //destructure feature3
  const { pretitle, title, subtitle, image } = feature4;
  return (
    <section className="section">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row lg:items-center lg:gap-x-[30px]">
          {/* image */}
          <div
            className="flex-1 order-2 lg:order-1 flex flex-col items-center"
            data-aos="fade-right"
            data-aos-offset="300"
          >
            <img
              className="w-[290px] cursor-pointer transition-all ease-out duration-300 hover:scale-110"
              src={image}
              alt=""
            />
          </div>
          {/* text */}
          <div
            className="flex-1 order-1 lg:order-2"
            data-aos="fade-left"
            data-aos-offset="400"
          >
            <div className="pretitle">{pretitle}</div>
            <h2 className="title">{title}</h2>
            <p className="lead">{subtitle}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature4;
