// import images
// import LogoImg from "../src/assets/img/header/MyFT(Logo01).png";
import LogoImg from "../src/assets/svg/MyFT.svg";
import HeroImg from "../src/assets/svg/hero-img.svg";
import OverviewProductImg from "../src/assets/svg/story.svg";
// import brand1 from "../src/assets/img/-customer/brand";
// import brand2 from "../src/assets/img/customer/brand";
// import brand3 from "../src/assets/img/customer/brand";
// import brand4 from "../src/assets/img/customer/brand";
// import brand5 from "../src/assets/img/customer/brand";
import Feature1Img from "../src/assets/svg/Mobile1.svg";
import Feature2Img from "../src/assets/svg/Mobile2.svg";
import Feature3Img from "../src/assets/svg/Mobile3.svg";
import Feature4Img from "../src/assets/svg/Mobile4.svg";
import ArrowRightImg from "../src/assets/img/features/arrow-right.svg";
// import CardIconImg1 from "../src/assets/img/product/cards/icon1.svg";
// import CardIconImg2 from "../src/assets/img/product/cards/icon2.svg";
// import CardIconImg3 from "../src/assets/img/product/cards/icon3.svg";
import CardIconImg1 from "../src/assets/svg/fa.svg";
import CardIconImg2 from "../src/assets/svg/ht.svg";
import CardIconImg3 from "../src/assets/svg/pp.svg";
import CardIconImg4 from "../src/assets/svg/ds.svg";
import PricingIcon1 from "../src/assets/img/pricing/icon1.svg";
import PricingIcon2 from "../src/assets/img/pricing/icon2.svg";
import PricingIcon3 from "../src/assets/img/pricing/icon3.svg";
import PricingIcon4 from "../src/assets/img/pricing/icon4.svg";
import AvatarImg1 from "../src/assets/img/testimonial/avatar1.png";
import AvatarImg2 from "../src/assets/img/testimonial/avatar2.png";
import AvatarImg3 from "../src/assets/img/testimonial/avatar3.png";
import AvatarImg4 from "../src/assets/img/testimonial/avatar4.png";
import AvatarImg5 from "../src/assets/img/testimonial/avatar5.png";
import CtaImg1 from "../src/assets/img/cta/image1.svg";
import CtaImg2 from "../src/assets/img/cta/image2.svg";
// import FacebookIcon from "../src/assets/img/copyright/facebook.svg";
// import TwitterIcon from "../src/assets/img/copyright/twitter.svg";
// import LinkedinIcon from "../src/assets/img/copyright/linkedin.svg";

export const header = {
  logo: LogoImg,
  btnText: "Sign Up Now",
};

export const nav = [
  { name: "Features", href: "#features" },
  { name: "Mission", href: "#mission" },
  { name: "Plans", href: "#plans" },
  { name: "How it Works", href: "#process" },
];

export const hero = {
  title: "Introducing to My Financial Therapist!",
  subtitle: "It's time to democratize financial literacy!",
  subtitleText: `Elevate your financial well-being with MyFT — the app that transforms your relationship with money. Harness AI-powered insights and expert advice to conquer financial stress, forge healthier habits, and unlock your true potential. Embrace the future with confidence. Welcome to MyFT — Personal finance with a therapeutic touch`,
  btnText: "Sign Up Now",
  compText: "— Web, iOS and Android",
  image: HeroImg,
};

export const overview = {
  productImg: OverviewProductImg,
  brands: [
    {
      image: OverviewProductImg,
      delay: 300,
    },
    // {
    //   image: ,
    //   delay: 300,
    // },
    // {
    //   image: ,
    //   delay: 400,
    // },
    // {
    //   image: ,
    //   delay: 500,
    // },
    // {
    //   image: ,
    //   delay: 600,
    // },
    // {
    //   image: ,
    //   delay: 700,
    // },
  ],
};

export const features = {
  feature1: {
    pretitle: "Step 1",
    title: "Financial Assessment",
    subtitle:
      "In “Discover”, Uncover your personal financial habits, beliefs, and stressors to build a stronger understanding of your relationship with money.",
    btnLink: "Learn more",
    btnIcon: ArrowRightImg,
    image: Feature1Img,
  },
  feature2: {
    pretitle: "Step 2",
    title: "Habit Tracking",
    subtitle:
      "Through “Reflect”, Develop and maintain healthy financial habits with the support of AI-driven coaching, progress tracking, and personalized feedback.",
    btnLink: "Learn more",
    btnIcon: ArrowRightImg,
    image: Feature2Img,
  },
  feature3: {
    pretitle: "Step 3",
    title: "Personalized Plans",
    subtitle:
      "Time to “Implement” with expert recommendations and tailored strategies to help you achieve your financial goals, from paying off debt to saving for retirement.",
    btnLink: "Learn more",
    btnIcon: ArrowRightImg,
    image: Feature3Img,
  },
  feature4: {
    pretitle: "Step 4",
    title: "Dynamic Support",
    subtitle:
      "Ongoing “Review” to access resources and expert advice on managing financial stress, boosting your financial self-esteem, and cultivating a positive money mindset.",
    btnLink: "Learn more",
    btnIcon: ArrowRightImg,
    image: Feature4Img,
  },
};

export const product = {
  title: "Some of Our Key Features",
  subtitle:
    "Bringing decades of experience to help elevate your financial well-being with a therapeutic touch  ",
  cards: [
    {
      icon: CardIconImg1,
      title: "Financial Assessment",
      subtitle: "Let's establish your baseline, then personalize your plan.",
      delay: 200,
    },
    {
      icon: CardIconImg2,
      title: "Habit tracking",
      subtitle:
        "Let's nurture good habits and remove those that hinder growth.",
      delay: 400,
    },
    {
      icon: CardIconImg3,
      title: "Personalized plans",
      subtitle: "Your financial plan should be as unique as you are.",
      delay: 600,
    },
    {
      icon: CardIconImg4,
      title: "Dynamic Support",
      subtitle: "We support you now and as life changes, because it will.",
      delay: 800,
    },
  ],
};

export const pricing = {
  title: "Custom Plans",
  cards: [
    {
      icon: PricingIcon1,
      title: "Non-Profit",
      services: [
        { name: "Financial Literacy" },
        { name: "Mission Driven Support" },
        { name: "Community Empowerment" },
      ],
      price: "$ TBD",
      userAmount: "Special rates, for early sign-ups",
      btnText: "Sign Up",
      delay: 300,
    },
    {
      icon: PricingIcon2,
      title: "Higher Education",
      services: [
        { name: "Financial Literacy" },
        { name: "Financial Independence" },
        { name: "Saving Support" },
      ],
      price: "$ TBD",
      userAmount: "Special rates, for early sign-ups",
      btnText: "Sign Up",
      delay: 600,
    },
    {
      icon: PricingIcon3,
      title: "Enterprise",
      services: [
        { name: "Employee Satisfaction" },
        { name: "Retirement Planning" },
        { name: "Therapeutic Approach" },
      ],
      price: "$ TBD",
      userAmount: "Special rates, for early sign-ups",
      btnText: "Sign Up",
      delay: 900,
    },
    {
      icon: PricingIcon4,
      title: "Individual / Family",
      services: [
        { name: "Financial Therapy / Literacy" },
        { name: "Automated Savings" },
        { name: "Dynamic Planning" },
      ],
      price: "$ TBD",
      userAmount: "Special rates, for early sign-ups",
      btnText: "Sign Up",
      delay: 900,
    },
  ],
};

export const testimonials = {
  title: "What our customers think",
  clients: [
    {
      message:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem nulla repellat debitis nostrum sint.",
      image: AvatarImg1,
      name: "MyFT Customer1",
      position: "CEO",
      borderColor: "#FF7235",
    },
    {
      message:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem nulla repellat debitis nostrum sint.",
      image: AvatarImg2,
      name: "MyFT Customer2",
      position: "CEO",
      borderColor: "#FFBE21",
    },
    {
      message:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem nulla repellat debitis nostrum sint.",
      image: AvatarImg3,
      name: "MyFT Customer3",
      position: "CEO",
      borderColor: "#4756DF",
    },
    {
      message:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem nulla repellat debitis nostrum sint.",
      image: AvatarImg4,
      name: "MyFT Customer4",
      position: "CEO",
      borderColor: "#3EC1F3",
    },
    {
      message:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem nulla repellat debitis nostrum sint.",
      image: AvatarImg5,
      name: "MyFT Customer5",
      position: "CEO",
      borderColor: "#BB7259",
    },
  ],
};

export const cta = {
  title: "Lets Get Started ",
  subtitle: "Sign up now",
  btnText: "Submit",
  img1: CtaImg1,
  img2: CtaImg2,
};

export const footer = {
  logo: LogoImg,
  links: [
    { name: "Home", href: "/" },
    { name: "About us", href: "/" },
    { name: "Careers", href: "/" },
    { name: "Pricing", href: "/" },
    { name: "Features", href: "/" },
    { name: "Blog", href: "/" },
  ],
  legal: [
    { name: "Terms of use", href: "/" },
    { name: "Terms of conditions", href: "/" },
    { name: "Privacy policy", href: "/" },
    { name: "Cookie policy", href: "/" },
  ],
  newsletter: {
    title: "Newsletter",
    subtitle: "Over 25000 people have subscribed",
  },
  form: {
    placeholder: "Enter your email",
    btnText: "Subscribe",
    smallText: "We don’t sell your email and spam",
  },
};
