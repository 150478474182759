import React, { useState, useEffect } from "react";
//import data
import { header } from "../data";
//import icons
import { HiOutlineMenu, HiOutlineX } from "react-icons/hi";
//import components
import MobileNav from "../components/MobileNav";
import Nav from "../components/Nav";

const Header = () => {
  //mobile nav state
  const [mobileNav, setMobileNav] = useState(false);
  //header state
  const [isActive, setIsActive] = useState(false);
  //de-structure header data
  const { logo, btnText } = header;
  //scroll event
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  // const toggleMobileNav = () => {
  //   setMobileNav(!mobileNav);
  // };

  const closeMobileNav = () => {
    setMobileNav(false);
  };

  return (
    <header
      className={`${
        isActive ? "lg:top-0 bg-white shadow-2xl" : "lg:top[60px]"
      } py-1 lg:py-4 fixed w-full transition-all z-10`}
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* logo */}
        <a href="/" data-aos="fade-down" data-aos-delay="1000">
          {/* <h1 className="text-3xl text-accent font-weight: 800;">Fin Track</h1> */}
          <img
            className="w-40 h-auto xs:w-24 xs:ml-[-30px] sm:w-32 sm:ml-[-30px] md:w-40 lg:w-48 xl:w-56"
            src={logo}
            alt=""
          />
        </a>
        {/* navbar - initially hidden - show on desktop */}
        <div
          className="hidden lg:flex"
          data-aos="fade-down"
          data-aos-delay="1200"
        >
          <Nav />
        </div>
        {/* cta button - initially hidden - show on desktop */}
        <a
          className="transition-all hover:scale-105 active:scale-95"
          href="#cta"
        >
          <button
            href="#cta"
            className="btn btn-sm btn-gradient rounded-full hidden text-white lg:flex"
            data-aos="fade-down"
            data-aos-delay="1400"
          >
            {btnText}
          </button>
        </a>
        {/* mobile nav trigger btn - hidden on desktop */}
        <button className="lg:hidden" onClick={() => setMobileNav(!mobileNav)}>
          {mobileNav ? (
            <HiOutlineX className="text-3xl text-blue-500" />
          ) : (
            <HiOutlineMenu className="text-3xl text-blue-500" />
          )}
        </button>
        {/* Mobile Nav hidden on desktop */}
        <div
          className={`${
            mobileNav ? "left-0" : "-left-full"
          } fixed top-0 bottom-0 w-[60vw] lg:hidden transition-all`}
          onClick={closeMobileNav}
        >
          <MobileNav />
        </div>
      </div>
    </header>
  );
};

export default Header;
