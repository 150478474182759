import React from "react";
// import data
import { overview } from "../data";

const Overview = () => {
  //de-structure overview
  const { productImg } = overview;
  return (
    <section
      id="mission"
      className="lg:min-h-[712px] bg-cover bg-left-top pt-[30px]"
    >
      <h2
        className="h2 mb-10 lg:mb-20 text-center"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        Our Mission
      </h2>
      <div className="container mx-auto flex flex-col justify-end overflow-hidden">
        <div className="flex justify-center">
          <img
            src={productImg}
            alt=""
            // data-aos="fade-up"
            // dta-aos-offset="300"
            className="w-[470px] mb-5"
          />
        </div>
        <div data-aos="fade-up" data-aos-offset="300">
          <p className="p-10 text-lg ml-10 mr-10 text-gray-500 shadow-lg mb-10 rounded-[10px]">
            We are on a mission to empower you to take control of your financial
            well-being. Our innovative approach to personal finance provides
            expert guidance, AI-driven insights, and a personalized roadmap that
            will help you overcome financial stress and achieve your goals.
            <br /> <br /> We understand that money is more than just numbers;
            it's deeply intertwined with our emotions, values, and beliefs.
            That's why we're committed to helping our users navigate the complex
            world of personal finance with confidence and ease.
            <br />
            <br /> With MyFT, you can confidently navigate your financial
            journey and create a more prosperous and fulfilling future. Don't
            wait to begin your transformation - get started today and experience
            the difference that financial wellness can make in your life.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Overview;
