import React from "react";
//import data
import { nav } from "../data";
import { header } from "../data";

const { btnText } = header;

const MobileNav = () => {
  return (
    <div className="bg-gradient-to-r from-cyan-500 to-blue-500/95 w-full h-full">
      <ul className="h-full flex flex-col justify-center gap-y-35 items-center gap-y-8">
        {nav.map((item, index) => {
          //de-structure item
          const { href, name } = item;
          return (
            <li key={index}>
              <a className="link text-white text-xl" href={`${href}`}>
                {name}
              </a>
            </li>
          );
        })}
        <li>
          <a href="#cta">
            <button className="transition-all hover:scale-105 active:scale-95 btn btn-sm bg-white rounded-full text-blue-500 lg:flex">
              {btnText}
            </button>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MobileNav;
