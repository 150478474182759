import React from "react";
//import our data
import { hero } from "../data";
//import icons
import { HiOutlineChevronDown } from "react-icons/hi";

const Hero = () => {
  //de-structure all hero data
  const { subtitle, subtitleText, btnText, image } = hero;
  return (
    <section className="min-h-[900px] py-24 mt-10">
      <div className="container mx-auto min-h-[900px] flex justify-center items-center">
        <div className="flex-1 flex-col lg:gap-x-[30px] gap-y-8 lg:gap-y-0 lg:flex-row items-center justify-center text-center lg:text-left">
          {/* text */}
          <div className="flex-1">
            <h1
              className="title mb-2 lg:mb5 mt-5"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              Introducing <br />{" "}
              <span className="font-sharpie text-blue-800">My</span>{" "}
              <span className="text-cyan-400">Financial Therapist</span>
            </h1>
            <p
              className="lead mb-5 lg:mb-10 mt-10 text-4xl text-green-400 font-bold"
              data-aos="fade-down"
              data-aos-delay="600"
            >
              {subtitle}
            </p>
            <p
              className="lead mb-5 lg:mb-10"
              data-aos="fade-down"
              data-aos-delay="600"
            >
              {subtitleText}
            </p>
            {/* btn & comp text */}
            <div
              className="flex items-center max-w-sm lg:max-w-full mx-auto lg:mx-0 gap-x-2 lg:gap-x-6  lg:justify-start sm:justify-center xs:justify-center"
              data-aos="fade-down"
              data-aos-delay="700"
            >
              <a href="#cta">
                <button className="transition-all hover:scale-105 active:scale-95 btn btn-md lg:btn-lg rounded-full btn-gradient text-white flex justify-center items-center lg:gap-x-4 shadow-lg sm:mx-auto">
                  {btnText} <HiOutlineChevronDown />
                </button>
              </a>
              {/* <span className="text-light lg:lead lg:mb-0">{compText}</span> */}
            </div>
          </div>
          {/* image */}
          <div className="flex-1" data-aos="fade-up" data-aos-delay="800">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
